import React from "react"
// import React, { useState, useEffect } from "react"
// import styled from 'styled-components'

//Firebase
// import firebase from "../firebase/firebase"

//Components
import Layout from "../components/Layout"
// import Seo from "../components/seo"

//Assets
// import facebook from "../assets/images/facebook.png"
// import instagram from "../assets/images/instagram.png"
// import twitter from "../assets/images/twitter.png"

const Empresa = () => {
  // const [data, setData] = useState({})
  // const [logo, setLogo] = useState("");
  // const [social, setSocial] = useState({});

  // useEffect(() => {
  //   let id = undefined
  //   if (typeof window !== 'undefined') id = window.location.search.split('?')[1];

  //   if (!(id === undefined || !id)) {
  //     firebase.firestore().collection("Partners").doc(id).get().then(doc => {
  //       if (doc.exists) {
  //         setData(doc.data())
  //         setSocial(doc.data().social)
  //         const logoRef = firebase.storage().ref().child('logos/' + id)

  //         logoRef.getDownloadURL().then(function (url) {
  //           setLogo(url)
  //         })
  //       }
  //     })
  //   }
  // }, [])

  return (
    <Layout>
      {/* <Seo title={data.name} /> */}
      {/* <Container>
        <LogoBoxResponsive>
          <Logo src={logo} alt="" />
        </LogoBoxResponsive>
        <Name>{data.name}</Name>
        <Content>
          <LogoBox>
            <Logo src={logo} alt="" />
          </LogoBox>
          <Box>
            <Description>{data.description}</Description>
            <Info>
              <span>Site: </span>
              <a href={data.site} target='_BLANK' rel="noopener noreferrer">{data.site}</a>
            </Info>
            <Info>
              <span>País: </span>
              {data.country}
            </Info>
            <Info>
              <span>Categoria: </span>
              {data.category}
            </Info>
            <Social>
              {social.facebook === "" ? "" : (
                <a href={social.facebook} target='_BLANK' rel="noopener noreferrer">
                  <img src={facebook} alt="Facebook" />
                </a>
              )}
              {social.instagram === "" ? "" : (
                <a href={social.instagram} target='_BLANK' rel="noopener noreferrer">
                  <img src={instagram} alt="Instagram" />
                </a>
              )}
              {social.twitter === "" ? "" : (
                <a href={social.twitter} target='_BLANK' rel="noopener noreferrer">
                  <img src={twitter} alt="Twitter" />
                </a>
              )}
            </Social>
          </Box>
          <Actions>
            <Label>Link:</Label>
            {data.sponsored === "" ?
              <Button
                disabled
              >
                Link Com Desconto
              </Button>
              :
              <Button
                href={data.sponsored}
                target='_BLANK'
                rel="noopener noreferrer"
              >
                Link Com Desconto
              </Button>
            }
          </Actions>
        </Content>
      </Container> */}
    </Layout>
  )
}

export default Empresa

// const Container = styled.section`
//   width: 100%;
//   height: auto;
//   max-width: 1240px;
//   margin: 0 auto;
//   padding: 0 20px;
// `

// const LogoBoxResponsive = styled.div`
//   display: none;

//   @media screen and (max-width: 1024px) {
//     width: 100%;
//     margin: 32px 16px;
//     display: flex;
//   }

//   @media screen and (max-width: 768px) {
//     justify-content: center;
//   }
// `

// const LogoBox = styled.div`
//   width: 17%;

//   @media screen and (max-width: 1024px) {
//     display: none;
//   }
// `

// const Logo = styled.img`
//   width: 100%;

//   @media screen and (max-width: 1024px) {
//     max-width: 320px;
//   }

//   @media screen and (max-width: 768px) {
//     max-width: 180px;
//   }
// `

// const Name = styled.h1`
//   font-family: var(--primary);
//   font-size: 48px;
//   margin-bottom: 16px;

//   @media screen and (max-width: 768px) {
//     font-size: 32px;
//     margin: 8px 16px;
//   }
// `
// const Content = styled.div`
//   display: flex;
//   margin-bottom: 64px;

//   @media screen and (max-width: 1024px) {
//     flex-direction: column;
//   }
// `

// const Box = styled.div`
//   width: 65%;
//   padding: 0 16px;
//   display: flex;
//   flex-direction: column;
//   font-family: var(--regular);
//   color: var(--blue);

//   @media screen and (max-width: 1024px) {
//     width: 100%;
//     margin-bottom: 16px;
//   }
// `
// const Description = styled.p`
//   font-size: 18px;
//   margin-bottom: 16px;
//   text-align: justify;
//   line-height: 24px;
// `
// const Info = styled.p`
//   font-size: 18px;
//   margin-bottom: 8px;

//   span {
//     font-weight: bold;
//   }

//   a {
//     text-decoration: none;
//     color: #67a99a;
//   }
// `

// const Social = styled.div`
//   display: flex;

//   a {
//     display: block;
//     margin-right: 8px;

//     img {
//       width: 32px;
//     }
//   }
// `

// const Actions = styled.div`
//   width: 18%;

//   @media screen and (max-width: 1024px) {
//     width: 100%;
//     max-width: 288px;
//     margin: 0 16px;
//   }
// `

// const Label = styled.p`
//   color: var(--blue);
//   font-family: var(--bold);
//   font-size: 14px;
//   margin-bottom: 8px;
// `

// const Button = styled.a`
//   width: 100%;
//   padding: 12px 12px;
//   border: none;
//   border-radius: 8px;
//   background: var(--green);
//   font-family: var(--primary);
//   font-size: 16px;
//   color: #FFF;
//   display: block;
//   text-align: center;
//   position: relative;
//   margin-bottom: 16px;
//   transition: all .5s;

//   &:hover{
//     transform: scale(1.1);
//   }

//   &:disabled {
//     background: grey;

//     &:hover{
//       transform: scale(1);
//     }
//   }
// `